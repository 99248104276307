/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import { convertDecimal } from "@cfxjs/data-format";
import { format } from "js-conflux-sdk/dist/js-conflux-sdk.umd.min.js";
import { BigNumber } from "@ethersproject/bignumber";
import Big from "big.js";
import { Contract } from "@ethersproject/contracts";

import { ContractConfig, ContractType } from "../constants/contractConfig";

import { ZeroAddrHex, TypeTransaction, SendStatus } from "../constants";
import { useIsNativeToken } from "../hooks/useZGWallet";
import { calculateGasMargin } from "../utils";
import { useIsCfxChain } from "../hooks";
import { useTxState } from "../state/transaction";
import { ERC20_ABI } from "../abi";
import { useWallet } from '../hooks/useZGWallet'

function useShuttleIn({
  fromChain,
  toChain,
  fromToken,
  toToken,
  value,
  fromAddress,
  toAddress,
  setSendStatus,
  fromWallet,
}) {
  const { address: fromTokenAddress, decimals, origin } = fromToken;
  const isCfxChain = useIsCfxChain(origin);
  const isNativeToken = useIsNativeToken(fromChain, fromTokenAddress);
  const { unshiftTx, transactions } = useTxState();
  const { web3Provider } = useWallet(fromWallet);
  window._transactions = new Map(Object.entries(transactions));

  function getTransactionStatusData(hash, type = TypeTransaction.transaction) {
    const data = {
      hash: hash,
      fromChain,
      toChain,
      fromAddress,
      toAddress,
      amount: value,
      fromToken,
      toToken,
      tx_type: type,
      cfxAddress: toAddress,
    };
    return data;
  }

  return async (shuttleInWalletAddress) => {
    setSendStatus(SendStatus.ongoing);
    if (isNativeToken) {
      let params = [
        format.hexAddress(toAddress),
        ZeroAddrHex,
        {
          value: new Big(
            convertDecimal(value, "multiply", decimals)
          ).toString(),
        },
      ];
      try {
        const drContract = new Contract(
          ContractConfig[ContractType.depositRelayer].address[fromChain],
          ContractConfig[ContractType.depositRelayer].abi,
          web3Provider.getSigner(),
        )
        let gas = await drContract.estimateGas.deposit(
          params[0],
          params[1],
          params[2]
        );
        drContract
          .deposit(params[0], params[1], {
            ...params[2],
            gasLimit: calculateGasMargin(gas),
          })
          .then((data) => {
            unshiftTx(getTransactionStatusData(data?.hash));
            // fetchShuttleData(data?.hash)
            setSendStatus(SendStatus.success);
          })
          .catch((error) => {
            console.warn(error);
            setSendStatus(SendStatus.error);
          });
      } catch (error) {
        console.warn(error);
        setSendStatus(SendStatus.error);
      }
    } else {
      if (!isCfxChain) {
        const drContract = new Contract(
          ContractConfig[ContractType.depositRelayer].address[fromChain],
          ContractConfig[ContractType.depositRelayer].abi,
          web3Provider.getSigner(),
        )
        let params = [
          fromTokenAddress,
          format.hexAddress(toAddress),
          ZeroAddrHex,
          new Big(convertDecimal(value, "multiply", decimals)).toString(),
          {
            value: BigNumber.from(0),
          },
        ];
        try {
          let gasDt = await drContract.estimateGas.depositToken(
            params[0],
            params[1],
            params[2],
            params[3],
            params[4]
          );
          drContract
            .depositToken(params[0], params[1], params[2], params[3], {
              ...params[4],
              gasLimit: calculateGasMargin(gasDt),
            })
            .then((data) => {
              unshiftTx(getTransactionStatusData(data?.hash));
              // fetchShuttleData(data?.hash)
              setSendStatus(SendStatus.success);
            })
            .catch((error) => {
              console.warn(error);
              setSendStatus(SendStatus.error);
            });
        } catch (error) {
          console.warn(error);
          setSendStatus(SendStatus.error);
        }
      } else {
        const amountVal = new Big(
          convertDecimal(value, "multiply", decimals)
        ).toString();
        try {
          const tokenContract = new Contract(
            fromTokenAddress,
            ERC20_ABI,
            web3Provider.getSigner(),
          );
          const gasData = await tokenContract.estimateGas.transfer(
            shuttleInWalletAddress,
            amountVal
          );
          tokenContract
            .transfer(shuttleInWalletAddress, amountVal, {
              gasLimit: calculateGasMargin(gasData),
            })
            .then((data) => {
              unshiftTx(getTransactionStatusData(data?.hash));
              // fetchShuttleData(data?.hash)
              setSendStatus(SendStatus.success);
            })
            .catch((error) => {
              console.warn(error);
              setSendStatus(SendStatus.error);
            });
        } catch (error) {
          console.warn(error);
          setSendStatus(SendStatus.error);
        }
      }
    }
  };
}

export default useShuttleIn;
