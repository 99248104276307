import {
  TokenBNBLogo,
  TokenBTCLogo,
  TokenCFXLogo,
  TokenDAILogo,
  TokenDefaultLogo,
  TokenETHLogo,
  TokenEmptyLogo,
  TokenUSDCLogo,
  TokenUSDTLogo,
} from '../assets/svg'

export const TokenConfig = {
}

export function TokenIconSrc(token) {
  let icon
  switch (token) {
    case 'BNB':
      icon = TokenBNBLogo
      break
    case 'BTC':
      icon = TokenBTCLogo
      break
    case 'CFX':
      icon = TokenCFXLogo
      break
    case 'DAI':
      icon = TokenDAILogo
      break
    case 'ETH':
      icon = TokenETHLogo
      break
    case 'USDC':
      icon = TokenUSDCLogo
      break
    case 'USDT':
      icon = TokenUSDTLogo
      break
    default:
      if (token?.length) icon = TokenDefaultLogo
      else icon = TokenEmptyLogo
      break
  }
  return icon
}

export const AddressOne = '0x0000000000000000000000000000000000000001';

export const tokenContractsInfo = {
  // cfx eSpace testnet
  '71+0x0000000000000000000000000000000000000001': { // CFX
    symbol: 'CFX',
    name: 'CFX',
    pegged: '0xc710c3ed93052c8177d356a15a4216f31030ad6e',
    priceName: 'CFXUSDT',
  },
  '71+0x7c8Db2BdB4A0EE722D8D4B70D2a02c15DDBaF517': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '71+0xa84D84BDBB4Abce2d1aef6bE85323953F877E01D': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },

  // bsc testnet
  '97+0x1cb9ee88a3c37a564364be9f4450c9d62a2c505d': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
    pegged: '0x78952f90a9b39b00e416fe2098fcfbc1e120854c',
    priceName: 'CFXUSDT',
  },
  '97+0x839f685FF05f3CcC6b8030c2BE23f09A39ed170D': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '97+0x64a23998EbCEb3138F1C7C0E7A6C029C0166B294': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },

  // Optimism Goerli
  '420+0x51ec0cfcb174ae37712d9041f6d29269c0d23cfd': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
  },
  '420+0x1397F53e72AF8776Cc7dBAa468fb191b3d2C2531': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '420+0x2757dC0AF733B79314bc5dBFA91b2922c18ECA88': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },

  // Arbitrum Goerli
  '421613+0x4c764b397de55948e0ba66b644b1f1f37fa4dd62': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
  },
  '421613+0x781F8b25a1Bb374A7c8fcff551f493397377106E': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '421613+0x6a88cC44C07654bC0ba5fA45450956a1a9868658': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },

  // Avalanche
  '43113+0x7BDDD49F9E3c924f347e47c7B5BA4a55393A610B': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
  },
  '421613+0x51Ec0Cfcb174AE37712D9041F6d29269c0d23CfD': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '421613+0x8C0b5f22C24c2BCDd38D21a3AfcB0D2e7F67D397': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },
};
