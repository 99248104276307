/* eslint-disable react-hooks/exhaustive-deps */
/**
 * For the chain based on Ethereum: multiple connectors
 */
import {useMemo} from 'react'
import { getContract} from '../utils/web3'
import {checkHexAddress} from '../utils/address'
import {ERC20_ABI} from '../abi'
import { useWallet } from './useZGWallet'

// returns null when has error
export function useContract(address, wallet, ABI, withSignerIfPossible = true) {
  const {web3Provider: library, address: account} = useWallet(wallet)
  return useMemo(() => {
    if (!address || !ABI || !library || !checkHexAddress(address)) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      return null
    }
  }, [address, Boolean(library), withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress, wallet, withSignerIfPossible = true) {
  return useContract(tokenAddress, wallet, ERC20_ABI, withSignerIfPossible)
}
