function SvgAlertTriangle(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.08 18.0266L13.76 4.21327C12.7467 2.39994 11.0933 2.39994 10.08 4.21327L1.76 18.0266C0.746669 19.8399 1.6 21.2799 3.62667 21.2799H20.2667C22.24 21.2799 23.0933 19.7866 22.08 18.0266V18.0266ZM11.1467 8.37327C11.1467 7.9466 11.52 7.57327 11.9467 7.57327C12.3733 7.57327 12.7467 7.9466 12.7467 8.37327V13.9733C12.7467 14.3999 12.3733 14.7733 11.9467 14.7733C11.52 14.7733 11.1467 14.3999 11.1467 13.9733V8.37327V8.37327ZM11.8933 18.1866C11.2533 18.1866 10.7733 17.6533 10.7733 17.0666C10.7733 16.4799 11.3067 15.9466 11.8933 15.9466C12.48 15.9466 13.0133 16.4799 13.0133 17.0666C13.0133 17.6533 12.5333 18.1866 11.8933 18.1866V18.1866Z"
        fill="#EC7910"
      />
    </svg>
  )
}

export default SvgAlertTriangle
