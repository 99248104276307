function SVGZGOpen(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 19H5V4.99997C5 4.99997 9.5 4.99997 11 4.99997C12.5 4.99997 12.5 2.99997 11 2.99997C9.5 2.99997 5 2.99997 5 2.99997C3.89 2.99997 3 3.89997 3 4.99997V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19C21 19 21 14 21 12.5C21 11 19 11 19 12.5C19 14 19 19 19 19Z"
        fill="#D1D5DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 4C13.5 3.44772 13.9477 3 14.5 3H20C20.5523 3 21 3.44772 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V6.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L17.5858 5H14.5C13.9477 5 13.5 4.55228 13.5 4Z"
        fill="#D1D5DB"
      />
    </svg>
  )
}

export default SVGZGOpen
