function ZGTokenSelectionChevron(props) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Path"
        d="M13.7391 1.41915L7.43909 7.80156C7.17483 8.06615 6.77934 8.06615 6.51509 7.80156L0.215097 1.41915C-0.0551613 1.12329 -0.0730549 0.637339 0.174504 0.316717C0.422062 -0.00390448 0.847256 -0.0454609 1.1391 0.222442L6.97709 6.13415L12.8151 0.222442C13.0007 0.0192469 13.2692 -0.0514715 13.5142 0.0382926C13.7592 0.128057 13.9413 0.363824 13.9882 0.652227C14.0352 0.940629 13.9395 1.23515 13.7391 1.41915Z"
        fill="#A9ABB2"
      />
    </svg>
  )
}

export default ZGTokenSelectionChevron
