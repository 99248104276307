import Big from 'big.js'

Big.DP = 40
Big.NE = -40
Big.PE = 1000

export const NetworkContextName = 'NETWORK'

export const TypeConnectWallet = {
  uninstalled: 'uninstalled',
  loading: 'loading',
  error: 'error',
  success: 'success',
}

export const ProxyUrlPrefix = {
  shuttleflow: '/rpctx',
  sponsor: '/rpctoken',
  zg: '/rpcnext',
}

/**
 * interval time config
 */
export const IntervalTime = {
  fetchBalance: 3000,
  fetchTokenList: 6000,
}

export const BigNumZero = new Big(0)

export const ZeroAddrHex = '0x0000000000000000000000000000000000000000'

export const TxReceiptModalType = {
  ongoing: 'ongoing',
  success: 'success',
  error: 'error',
}

export const SendStatus = {
  ongoing: 'waiting',
  success: 'success',
  error: 'failed',
  claim: 'claim',
}

export const ClaimStatus = {
  ongoing: 'ongoing',
  success: 'success',
  error: 'error',
}

export const MobileBreakpoint = 768

export const Decimal18 = '18'

export const TypeAccountStatus = {
  unconnected: 'unconnected',
  success: 'success',
  error: 'error',
}

/**
 * Type of cached tx type
 */
export const TypeTransaction = {
  transaction: 'transaction', //common transaction,for example: send for native token or transfer for erc20 token
  approve: 'approve', //approve for erc20 token
}

/**
 * Status of shuttle transaction, mainly for local environment
 */
export const TransactionStatus = {
  pending: 'pending',
  waiting: 'waiting',
  success: 'success',
  error: 'error',
  skip: 'skip',
}

/**
 * Status of zg deposit
 */
export const DepositStatus = {
  wait_for_confirm: 'WAIT_FOR_CONFIRM',
  wait_for_signature: 'WAIT_FOR_SIGNATURE',
  wait_for_claim: 'WAIT_FOR_CLAIM',
  claiming: 'CLAIMING',
  claimed: 'CLAIMED',
  failed: 'CLAIM_FAILED',
}

export const UserOperationStatus = {
  doing: 'doing',
  finished: 'finished',
}

export const Millisecond = {
  day: 24 * 60 * 60 * 1000,
}

export const ClaimButtonType = {
  twoStep: 'twoStep',
  common: 'common',
}

export const TxStatus = {
  submitted: 'submitted',
  success: 'success',
  error: 'error',
}

export const supportedPools = [
  '97+0x1cb9ee88a3c37a564364be9f4450c9d62a2c505d',
  '71+0x0000000000000000000000000000000000000001',
];

export * from './chainConfig'
export * from './contractConfig'
export * from './tokenConfig'
export * from './walletConfig'
