function SvgCheckedCircle(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<mask id="mask0_1240_10218" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<circle cx="10" cy="10" r="10" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_1240_10218)">
<circle cx="10.0003" cy="10.0013" r="8.33333" fill="#843EB4"/>
<path d="M12.9167 7.08203L9.52652 11.6654L7.5 10.1029" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>

  )
}

export default SvgCheckedCircle
