function SvgClear(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M9.58333 17.9167C14.1857 17.9167 17.9167 14.1857 17.9167 9.58333C17.9167 4.98096 14.1857 1.25 9.58333 1.25C4.98096 1.25 1.25 4.98096 1.25 9.58333C1.25 14.1857 4.98096 17.9167 9.58333 17.9167Z" fill="#D1D5DB"/>
  <path d="M12.5 7.5L7.5 12.5" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 7.5L12.5 12.5" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

}

export default SvgClear;
