function SVGZGEditWallet(props) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.1122 3.3335H4.2495C3.80526 3.3335 3.3792 3.50909 3.06507 3.82165C2.75094 4.13421 2.57446 4.55814 2.57446 5.00016V16.6668C2.57446 17.1089 2.75094 17.5328 3.06507 17.8453C3.3792 18.1579 3.80526 18.3335 4.2495 18.3335H15.9748C16.419 18.3335 16.8451 18.1579 17.1592 17.8453C17.4734 17.5328 17.6498 17.1089 17.6498 16.6668V10.8335" stroke="#A9ABB2" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.3936 2.08368C16.7268 1.75216 17.1787 1.56592 17.6499 1.56592C18.1211 1.56592 18.573 1.75216 18.9061 2.08368C19.2393 2.41521 19.4265 2.86484 19.4265 3.33368C19.4265 3.80253 19.2393 4.25216 18.9061 4.58368L10.9497 12.5004L7.59961 13.3337L8.43713 10.0004L16.3936 2.08368Z" stroke="#A9ABB2" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SVGZGEditWallet

