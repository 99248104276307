import connectors from "../connectors";
import { ChainConfig } from "../constants";

export default async(fromWallet, fromToken, fromTokenKey) => {
  try {
    await connectors.getConnector(fromWallet)?.switchChain(Number(fromToken.chain_id))
  } catch (err) {
    if (err?.code === 4902) {
      console.log('network missing')
    }
    if ((String(err) || '').includes('not configured for connector')) {
      try {
        const provider = await connectors.getConnector(fromWallet).getProvider();
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: `0x${Number(fromToken.chain_id).toString(16)}`,
            chainName: ChainConfig[fromTokenKey].fullName,
            nativeCurrency: {
              symbol: ChainConfig[fromTokenKey].tokenName,
              decimals: ChainConfig[fromTokenKey].decimals,
            },
            rpcUrls: [ChainConfig[fromTokenKey].rpcUrl],
          }],
        });
      } catch (err) {
        console.log('add network failed: ', err)
        Notification.open({
          type: 'error',
          message: 'add network failed',
        })
      }
    }
  }
}
