function SVGZGFlip(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_88_2233)">
<path fillRule="evenodd" clipRule="evenodd" d="M18 3.99999C18.5523 3.99999 19 4.4477 19 4.99999V21C19 21.5523 18.5523 22 18 22C17.4477 22 17 21.5523 17 21V4.99999C17 4.4477 17.4477 3.99999 18 3.99999Z" fill="#9CA3AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.7071 10.7071C23.3166 11.0976 22.6834 11.0976 22.2929 10.7071L18 6.4142L13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071C11.9024 10.3166 11.9024 9.6834 12.2929 9.29288L17.2929 4.29288C17.4804 4.10534 17.7348 3.99999 18 3.99999C18.2652 3.99999 18.5196 4.10534 18.7071 4.29288L23.7071 9.29288C24.0976 9.6834 24.0976 10.3166 23.7071 10.7071Z" fill="#9CA3AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6 22C5.44771 22 5 21.5523 5 21L5 4.99998C5 4.4477 5.44772 3.99998 6 3.99998C6.55229 3.99998 7 4.4477 7 4.99998L7 21C7 21.5523 6.55228 22 6 22Z" fill="#9CA3AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M0.292893 15.2929C0.683418 14.9024 1.31658 14.9024 1.70711 15.2929L6 19.5858L10.2929 15.2929C10.6834 14.9024 11.3166 14.9024 11.7071 15.2929C12.0976 15.6834 12.0976 16.3166 11.7071 16.7071L6.70711 21.7071C6.51957 21.8946 6.26522 22 6 22C5.73478 22 5.48043 21.8946 5.29289 21.7071L0.292893 16.7071C-0.0976312 16.3166 -0.097631 15.6834 0.292893 15.2929Z" fill="#9CA3AF"/>
</g>
<defs>
<clipPath id="clip0_88_2233">
<rect width="24" height="24" fill="white" transform="translate(24) rotate(90)"/>
</clipPath>
</defs>
</svg>

  )
}

export default SVGZGFlip
