import React from 'react'
import PropTypes from 'prop-types'
import {SupportedWallets, WalletConfig} from '../../../constants'

function WalletIcon({type, size = 30}) {
  const {icon, name } = WalletConfig[type]
  return <div
    style={{
      width: size,
      height: size,
    }}
  >
    <img
      src={icon}
      alt={`wallet ${name}`}
      className="max-w-none"
      width={size}
      height={size}
    />
  </div>
}

WalletIcon.propTypes = {
  type: PropTypes.oneOf(SupportedWallets).isRequired,
  size: PropTypes.number,
}

export default WalletIcon
