import PropTypes from "prop-types";

import { Button } from "../../components";

const PortalMarketing = ({ handleClickStart, className, onAnimationEnd }) => {
  return (
    <div className={`w-[328px] md:w-[536px] mt-[57px] ${className}`} onAnimationEnd={onAnimationEnd}>
      <div className="flex flex-col gap-[24px]">
        <p className="text-[36px] md:text-[56px] font-semibold leading-[120%]">Make a fast and secure transaction</p>
        <p className="text-[16px] leading-[150%] tracking-[-0.48px]">{'With <1 sec finality and coverage across most chains, Zero Gravity allows you to seamlessly and securely transfer your assets for a friction-free Web3.'}</p>
        <div className="flex gap-[32px]">
          <div className="text-center">
            <p className="text-[24px] md:text-[32px] text-primary-fuscia font-bold leading-[150%]">Fast</p>
            <p className="text-[16px] md:text-[24px] text-primary-fuscia font-medium leading-[150%]">Finality</p>
          </div>
          <div className="h-[61px] md:h-[85px] w-[1px] bg-primary-fuscia" />
          <div className="text-center">
            <p className="text-[24px] md:text-[32px] text-primary-fuscia font-bold leading-[150%]">Top 20</p>
            <p className="text-[16px] md:text-[24px] text-primary-fuscia font-medium leading-[150%]">Blockchains</p>
          </div>
          <div className="h-[61px] md:h-[85px] w-[1px] bg-primary-fuscia" />
          <div className="text-center">
            <p className="text-[24px] md:text-[32px] text-primary-fuscia font-bold leading-[150%]">&140M</p>
            <p className="text-[16px] md:text-[24px] text-primary-fuscia font-medium leading-[150%]">TVL</p>
          </div>
        </div>
      </div>
      <Button className="mt-[40px] md:mt-[56px] h-[64px] w-[205px] text-[24px] font-semibold rounded-full" onClick={handleClickStart}>Get Started</Button>
    </div>
  );
}

PortalMarketing.propTypes = {
  handleClickStart: PropTypes.func.isRequired,
  className: PropTypes.string,
  onAnimationEnd: PropTypes.func,
}

export default PortalMarketing;
