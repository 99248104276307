import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'

import { useIsMobile } from '../../hooks'

import Form from './Form'
import PortalMarketing from './Marketing'
import './animation.css'

const ZGPortal = ({ setHideWalletList, setHideMarketManul }) => {
  useEffect(() => {
    setHideWalletList([])
  }, [setHideWalletList]);

  const [marketHide, setMarketHide] = useState(false);
  const mobileContainer = useRef(null);
  const portalContainer = useRef(null);

  useEffect(() => {
    const isMarketHide = localStorage.getItem('isMarketHide')
    if (isMarketHide) {
      setMarketHide(true)
    }
  }, [])

  const [marketClass, setMarketClass] = useState('');
  const [containerClass, setContainerClass] = useState('');

  const isMobile = useIsMobile();
  const handleHideMarket = () => {
    if (isMobile) {
      mobileContainer.current.scrollTo({
        top: 500,
      });
      localStorage.setItem('isMarketHide', true)
      return;
    }
    const portalLeft = portalContainer.current.getBoundingClientRect().left;
    const styleSheet = document.createElement("style", {
      type: "text/css",
    });
    let halfPortalWidth = 504;
    if (window.innerWidth < 1440) {
      halfPortalWidth = 472;
    }
    if (window.innerWidth < 1024) {
      halfPortalWidth = 352;
    }
    const keyFrames = `
      @keyframes movePortalToCenter {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(50vw - ${halfPortalWidth}px - ${portalLeft}px));
        }
      }
    `;
    styleSheet.innerHTML = keyFrames;
    document.head.appendChild(styleSheet);
    setMarketClass('hide-market-to-left')
    setContainerClass('move-portal-to-center')
    localStorage.setItem('isMarketHide', true)
  }

  const handleClickPortal = () => {
    if (marketHide) return
    handleHideMarket();
  }

  const handleMarketAnimationEnd = () => {
    setHideMarketManul && setHideMarketManul();
  }

  return isMobile ? (
    <div className='overflow-scroll' ref={mobileContainer}>
      <PortalMarketing handleClickStart={handleHideMarket} className={`mb-[64px] ${marketClass}`} />
      <Form onClick={handleClickPortal} />
    </div>
  ) : (
    <div className={`flex xl:gap-[146px] lg:gap-[84px] md:gap-[16px] pl-8 relative ${containerClass}`} onAnimationEnd={handleMarketAnimationEnd}>
      {/* eslint-disable-next-line */}
      <div className='w-full h-full absolute xl:left-[682px] lg:left-[620px] md:left-[552px]' onClick={handleClickPortal} />
      <PortalMarketing handleClickStart={handleHideMarket} className={marketClass} />
      <Form ref={portalContainer} />
    </div>
  )
}

ZGPortal.propTypes = {
  setHideWalletList: PropTypes.func.isRequired,
  setHideMarketManul: PropTypes.func,
}

export default ZGPortal
