import { useState, useEffect } from "react";
import Big from "big.js";

import { KeyOfCfx } from "../constants/chainConfig";
import useShuttleAddress from "../hooks/useShuttleAddress";
import { useIsCfxChain, useIsBtcChain } from "../hooks";
import { useShuttleContract } from "../hooks/useShuttleContract";
import { ContractType } from "../constants/contractConfig";
import { ZeroAddrHex, TypeTransaction, SendStatus } from "../constants";
import { getExponent, calculateGasMargin } from "../utils";
import { useTxState } from "../state/transaction";
import { useZGFormState } from "../state/zgForm";

function useShuttleOut({
  fromChain,
  toChain,
  fromToken,
  toToken,
  value,
  fromAddress,
  toAddress,
  setSendStatus,
}) {
  const { origin, decimals, ctoken } = toToken;
  const isCfxChain = useIsCfxChain(origin);
  const isToChainBtc = useIsBtcChain(toChain);
  const [outAddress, setOutAddress] = useState("");
  const shuttleAddress = useShuttleAddress(
    outAddress,
    fromChain,
    toChain,
    "out"
  );
  const tokenBaseContract = useShuttleContract(ContractType.tokenBase);
  const drCfxContract = useShuttleContract(
    ContractType.depositRelayerCfx,
    toChain
  );
  const { toBtcAddress } = useZGFormState();
  const { unshiftTx, transactions } = useTxState();
  window._transactions = new Map(Object.entries(transactions));
  useEffect(() => {
    if (isToChainBtc) {
      setOutAddress(toBtcAddress);
    } else {
      setOutAddress(toAddress);
    }

  }, [isToChainBtc, toAddress, toBtcAddress]);

  function getTransactionStatusData(hash, type = TypeTransaction.transaction) {
    const data = {
      hash: hash,
      fromChain,
      toChain,
      fromAddress,
      toAddress,
      amount: new Big(value).toString(10),
      fromToken,
      toToken,
      tx_type: type,
      shuttleAddress: shuttleAddress,
      cfxAddress: fromAddress,
    };
    return data;
  }

  return async () => {
    setSendStatus(SendStatus.ongoing);
    if (isCfxChain) {
      const amountVal = Big(value).mul(getExponent(decimals));
      if (ctoken === KeyOfCfx) {
        try {
          const estimateData = await drCfxContract
            .deposit(toAddress, ZeroAddrHex)
            .estimateGasAndCollateral({
              from: fromAddress,
              value: amountVal,
            });
          drCfxContract
            .deposit(toAddress, ZeroAddrHex)
            .sendTransaction({
              from: fromAddress,
              value: amountVal,
              gas: calculateGasMargin(estimateData?.gasLimit, 0.5),
              storageLimit: calculateGasMargin(
                estimateData?.storageCollateralized,
                0.5
              ),
            })
            .then((txHash) => {
              unshiftTx(getTransactionStatusData(txHash));
              // fetchShuttleData(txHash)
              setSendStatus(SendStatus.success);
            })
            .catch((error) => {
              console.warn(error);
              setSendStatus(SendStatus.error);
            });
        } catch (error) {
          console.warn(error);
          setSendStatus(SendStatus.error);
        }
      } else {
        try {
          const estimateData = await drCfxContract
            .depositToken(ctoken, toAddress, ZeroAddrHex, amountVal)
            .estimateGasAndCollateral({
              from: fromAddress,
            });
          drCfxContract
            .depositToken(ctoken, toAddress, ZeroAddrHex, amountVal)
            .sendTransaction({
              from: fromAddress,
              gas: calculateGasMargin(estimateData?.gasLimit, 0.5),
              storageLimit: calculateGasMargin(
                estimateData?.storageCollateralized,
                0.5
              ),
            })
            .then((txHash) => {
              unshiftTx(getTransactionStatusData(txHash));
              // fetchShuttleData(txHash)
              setSendStatus(SendStatus.success);
            })
            .catch((error) => {
              console.warn(error);
              setSendStatus(SendStatus.error);
            });
        } catch (error) {
          console.warn(error);
          setSendStatus(SendStatus.error);
        }
      }
    } else {
      const amountVal = Big(value).mul(getExponent(18));
      try {
        const estimateData = await tokenBaseContract["burn"](
          fromAddress,
          amountVal,
          0,
          outAddress,
          ZeroAddrHex
        ).estimateGasAndCollateral({
          from: fromAddress,
          to: ctoken,
        });
        tokenBaseContract["burn"](
          fromAddress,
          amountVal,
          0,
          outAddress,
          ZeroAddrHex
        )
          .sendTransaction({
            from: fromAddress,
            to: ctoken,
            gas: calculateGasMargin(estimateData?.gasLimit, 0.5),
            storageLimit: calculateGasMargin(
              estimateData?.storageCollateralized,
              0.5
            ),
          })
          .then((data) => {
            unshiftTx(getTransactionStatusData(data));
            // fetchShuttleData(data)
            setSendStatus(SendStatus.success);
          })
          .catch((error) => {
            console.warn(error);
            setSendStatus(SendStatus.error);
          });
      } catch (error) {
        console.warn(error);
        setSendStatus(SendStatus.error);
      }
    }
  };
}

export default useShuttleOut;
