import { useEffect, useMemo, useCallback } from 'react';
import { Contract } from '@ethersproject/contracts';
import Big from 'big.js';
import { formatUnits } from '@ethersproject/units';

import { BCFX_ABI } from '../abi';
import { findChainConfigById, KeyOfPortal } from '../constants';
import { useBalance as useFluentBalance } from './usePortal';
import { useWallet } from './useZGWallet';
import { useZGFormState } from '../state/zgForm';
import { BigNumber } from '@ethersproject/bignumber';

export const getWeb3Balance = async (web3Provider, address, contractAddress, chainId) => {
  if (!web3Provider || !address) return BigNumber.from(0);
  const isNativeToken = contractAddress === findChainConfigById(String(chainId))?.nativeToken;
  if (!isNativeToken) {
    const contract = new Contract(contractAddress, BCFX_ABI, web3Provider);
    return contract.balanceOf(address);
  } else {
    return web3Provider.getBalance(address);
  }
}

export const useZGBalance = (wallet, chainId, contractAddress, decimals) => {
  const { balance, setBalance } = useZGFormState();

  const { address, chainId: walletChainId, web3Provider } = useWallet(wallet);
  const balanceFluent = useFluentBalance(address, contractAddress);

  const fetchWeb3Balance = useCallback(async () => {
    if (!web3Provider || !address) return;
    if (wallet === KeyOfPortal) return;
    if (chainId !== walletChainId) {
      setBalance(0)
      return; // only fetch balance when chainId is matched
    }
    getWeb3Balance(web3Provider, address, contractAddress, chainId)
    .then(setBalance)
    .catch(err => {
      setBalance(0);
      console.log(err)
    });
  }, [address, contractAddress, chainId, walletChainId, wallet, web3Provider, setBalance]);

  useEffect(() => {
    if (wallet === KeyOfPortal) {
      setBalance(balanceFluent);
    } else {
      fetchWeb3Balance();
    }
  }, [wallet, balanceFluent, fetchWeb3Balance, setBalance]);

  const displayBalance = useMemo(() => {
    if (!balance) return '0';
    if (wallet !== KeyOfPortal) {
      return formatUnits(new Big(balance).toString(), decimals || 18).slice(0, 7);
    } else if (wallet === KeyOfPortal) {
      return formatUnits(new Big(balance).toString(), decimals || 18).slice(0, 7);
    }
    return '0';
  }, [balance, wallet, decimals]);

  return {
    balance,
    displayBalance,
  }
};
