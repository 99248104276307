function BgRightCircle(props) {
  return (
    <svg width="941" height="941" viewBox="0 0 941 941" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<ellipse cx="470.234" cy="470.423" rx="256.571" ry="256.571" transform="rotate(-15 470.234 470.423)" fill="url(#paint0_linear_58_120)" fillOpacity="0.2"/>
<ellipse cx="470.234" cy="470.423" rx="294.715" ry="294.715" transform="rotate(-15 470.234 470.423)" fill="url(#paint1_linear_58_120)" fillOpacity="0.1"/>
<path opacity="0.3" d="M52.7602 522.679C23.9298 291.98 187.576 81.5908 418.274 52.7604C648.973 23.93 859.362 187.576 888.193 418.275C917.023 648.973 753.377 859.362 522.678 888.193C291.98 917.023 81.5906 753.377 52.7602 522.679Z" stroke="#FFAF90"/>
<path opacity="0.8" d="M126.135 513.508C102.369 323.334 237.27 149.9 427.445 126.134C617.619 102.368 791.053 237.269 814.819 427.443C838.585 617.618 703.684 791.052 513.509 814.818C323.335 838.584 149.901 703.683 126.135 513.508Z" stroke="#FFAF90"/>
<path opacity="0.5" d="M98.0652 517.017C72.3616 311.339 218.259 123.768 423.937 98.0644C629.614 72.3608 817.186 218.259 842.889 423.936C868.593 629.614 722.695 817.185 517.018 842.888C311.34 868.592 123.769 722.694 98.0652 517.017Z" stroke="#FFAF90"/>
<defs>
<linearGradient id="paint0_linear_58_120" x1="345.103" y1="138.142" x2="470.234" y2="695.449" gradientUnits="userSpaceOnUse">
<stop stopColor="#BC50FF"/>
<stop offset="1" stopColor="#53B7FF"/>
</linearGradient>
<linearGradient id="paint1_linear_58_120" x1="326.5" y1="88.743" x2="470.234" y2="728.903" gradientUnits="userSpaceOnUse">
<stop stopColor="#BC50FF"/>
<stop offset="1" stopColor="#53B7FF"/>
</linearGradient>
</defs>
</svg>

  );
}

export default BgRightCircle;
