import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useTxData} from '../../hooks/useTransaction'
import {TransactionStatus} from '../../constants'
import {TypeTransaction} from '../../constants'
import {Button, Input} from '../../components'
import {ZGSearchIcon} from '../../assets/svg'
import HistoryItem from './HistoryItem'
import HistoryDetailModal from './HistoryDetailModal'

function divideByMonth(list, searchKey) {
  const groups = {}
  const res = []

  const filtered = searchKey ? list.filter(item => {
    const values = Object.values(item)
    for (let value of values) {
      if (JSON.stringify(value).toLowerCase().indexOf(searchKey) > -1) {
        return true
      }
    }
  }) : list

  filtered.forEach(item => {
    const dt = new Date(item.timestamp)
    const options = { month: 'long', year: 'numeric' };
    const monthYear = dt.toLocaleString('en-US', options); // to do: set locale when i18n enabled

    if (!groups[monthYear]) {
      groups[monthYear] = []
      res.push({
        key: monthYear,
        items: groups[monthYear],
      })
    }

    groups[monthYear].push(item)
  })

  return res
}

function History() {
  const {t} = useTranslation()
  const [searchKey, setSearchKey] = useState('');

  const historyData = useTxData(
    [
      TransactionStatus.pending,
      TransactionStatus.waiting,
      TransactionStatus.success,
      TransactionStatus.error,
    ],
    [TypeTransaction.transaction],
  )
  const dataBlocks = divideByMonth(historyData, searchKey)
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)
  const [transaction, setTransaction] = useState({})

  const handleShowModal = (tx) => {
    setTransaction(tx)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setTransaction({})
    setShowModal(false)
  }

  return (
    <div className="px-6 md:px-10 lg:px-16" id="history">
      <h1 className="text-lg lg:text-2lg font-semibold">{t('history.title')}</h1>
      <div className="flex flex-1 flex-col w-full overflow-y-auto">
        {historyData.length === 0 && (
          <div className="flex flex-col">
            <p className="mt-7 text-xs md:text-base">
              {t('history.empty')}
            </p>
            <Button
              className="mt-20 w-60 md:w-80 self-center"
              size="large"
              onClick={() => history.push('/')}
            >
              {t('history.portalCTA')}
            </Button>
          </div>
        )}
        {historyData.length !== 0 && (
          <div className="mt-6 md:mt-8">
            <Input
              width="w-full"
              customPrefix={<ZGSearchIcon className="md:ml-8 ml-4" />}
              placeholder={t('history.search')}
              onChange={e => setSearchKey(e.target.value)}
            />
            <div>
              {dataBlocks.map((block, i) => (
                <div key={i} className="mt-8">
                  <h1 className="text-sm lg:text-lg font-semibold">{block.key}</h1>
                  <div className="border-t mt-3 pt-2">
                    {block.items.map((item, j) => (
                      <HistoryItem key={j} historyItemData={item} onClick={() => handleShowModal(item)}/>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <HistoryDetailModal
              open={showModal}
              onClose={handleCloseModal}
              transaction={transaction}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default History
