function SVGZGCopy(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 1C14 1 4 1 4 1C2.9 1 2 1.9 2 3C2 3 2 15 2 16.5C2 18 4 18 4 16.5C4 15 4 3 4 3C4 3 14 3 15.5 3C17 3 17 1 15.5 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
        fill="#D1D5DB"
      />
    </svg>
  )
}

export default SVGZGCopy
