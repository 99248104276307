import PropTypes from 'prop-types'
import { useEffect } from 'react'

import Form from './Form'
import './animation.css'

const ZGPortal = ({ setHideWalletList }) => {
  useEffect(() => {
    setHideWalletList([])
  }, [setHideWalletList]);

  return <Form />
}

ZGPortal.propTypes = {
  setHideWalletList: PropTypes.func.isRequired,
}

export default ZGPortal
