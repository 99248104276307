import create from 'zustand'
import {KeyOfPortal, KeyOfMetaMask, KeyOfCoinbase, KeyOfOkx} from '../constants'

let Store = null

const createStore = () =>
  create(set => ({
    init: false,
    walletMap: {
      [KeyOfPortal]: {},
      [KeyOfMetaMask]: {},
      [KeyOfCoinbase]: {},
      [KeyOfOkx]: {},
    },
    initialize: () => set({init: true}),
    setWalletMap: walletMap => set({walletMap}),
    setField: (walletKey, key, value) =>
      set(state => {
        const walletMap = state.walletMap
        if (walletMap[walletKey]) {
          walletMap[walletKey][key] = value
          return walletMap
        }
        return state.walletMap
      }),
  }))

export const useWalletState = () => {
  if (!Store) Store = createStore()
  const useStore = Store
  const state = useStore()

  return state
}
