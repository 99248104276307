import {connect} from '@cfxjs/use-wallet'
import {KeyOfPortal, KeyOfMetaMask, KeyOfCoinbase, KeyOfOkx} from '../constants'
import {metaMaskConnector} from './metaMask'
import {coinbaseConnector} from './coinbase'
import {okxConnector} from './okx'

class ConnectorHub {
  constructor() {
    this.map = []
  }

  getConnector(wallet) {
    return this.map[wallet]
  }

  initEvents(connector, options) {
    if (options) {
      connector.getProvider().then(p => {
        options.onAccountsChanged &&
          p.on('accountsChanged', options.onAccountsChanged)
        options.onChainChanged && p.on('chainChanged', options.onChainChanged)
      })
    }
  }

  createConnector(wallet, options) {
    let con
    switch (wallet) {
      case KeyOfPortal:
        con = {
          connect,
        }
        break
      case KeyOfMetaMask:
        con = metaMaskConnector
        this.initEvents(con, options)
        break
      case KeyOfCoinbase:
        con = coinbaseConnector
        this.initEvents(con, options)
        break
      case KeyOfOkx:
        con = okxConnector
        this.initEvents(con, options)
        break
      default:
        break
    }
    this.map[wallet] = con
    return this.map[wallet]
  }
}

const connectorHub = new ConnectorHub()
export default connectorHub
