/**
 * Store for necessary transaction data of zg flow
 */

import create from 'zustand'
import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { KeyOfBtc, KeyOfCfx, KeyOfPortal } from '../constants'

let Store = null

const createStore = () =>
  create(set => ({
    /**
     * {
     * // info from backend
     *  chain_id: string;
     *  token_address: string;
     * token_abbr: string;
     * decimals: number;
     * pegged_address: string;
     *
     * // info hard coded in ChainConfig
     * chainName: string;
     * provider: Provider;
     * nativeToken: string;
     *
     * // info from chain
     * symbol: string;
     * name: string;
     *
     * // info for shuttleflow bridges
     * toChains?: string[];
     * isSF?: boolean;
     * }
     */
    walletInit: false,
    fromToken: {}, // should be from useZGTokenList
    toToken: {}, // should be from useZGTokenList
    fromAmount: '',
    toAmount: '',
    toAddress: '',
    fee: BigNumber.from(0),
    fromWallet: '',
    toWallet: '',
    sameWallet: true,
    lastWeb3Wallet: '',
    fromBtcAddress: '', // only for sf btc now
    toBtcAddress: '', // only for sf btc now
    defaultSetLock: false, // lock auto set default token
    balance: null,
    setWalletInit: () => set({walletInit: true}),
    setFromToken: fromToken => set({fromToken}),
    setToToken: toToken => set({toToken}),
    setFromAmount: fromAmount => set({fromAmount}),
    setToAmount: toAmount => set({toAmount}),
    setToAddress: toAddress => set({toAddress}),
    setFee: fee => set({fee}),
    setFromWallet: fromWallet => set((state) => {
      let sameWallet = state.sameWallet
      let toWallet = sameWallet ? fromWallet : state.toWallet
      let lastWeb3Wallet = state.lastWeb3Wallet
      if (fromWallet === KeyOfPortal) {
        sameWallet = false
        toWallet = lastWeb3Wallet
      } else {
        lastWeb3Wallet = fromWallet
      }
      return {
        fromWallet,
        lastWeb3Wallet,
        sameWallet,
        toWallet,
      }
    }),
    setToWallet: toWallet => set({toWallet}),
    setSameWallet: sameWallet => set((state) => {
      return {
        sameWallet,
        toWallet: sameWallet ? state.fromWallet : state.toWallet
      }
    }),
    setFromBtcAddress: fromBtcAddress => set({fromBtcAddress}),
    setToBtcAddress: toBtcAddress => set({toBtcAddress}),
    setDefaultSetLock: defaultSetLock => set({defaultSetLock}),
    setBalance: balance => set({balance}),
  }))

export const useZGFormState = () => {
  if (!Store) Store = createStore()
  const useStore = Store
  const state = useStore()

  return state
}

export const useIsFromChainBtc = () => {
  const { fromToken } = useZGFormState();

  return useMemo(() => {
    return (fromToken?.sfChain || '').toLowerCase() === KeyOfBtc
  }, [fromToken.sfChain]);
}

export const useIsToChainBtc = () => {
  const { toToken } = useZGFormState();

  return useMemo(() => {
    return (toToken?.sfChain || '').toLowerCase() === KeyOfBtc
  }, [toToken.sfChain]);
}

export const useIsFromChainCfx = () => {
  const { fromToken } = useZGFormState();

  return useMemo(() => {
    return fromToken.sfChain === KeyOfCfx
  }, [fromToken.sfChain]);
}
