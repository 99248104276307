import create from 'zustand'

let Store = null

const createStore = () =>
  create(set => ({
    toBtcAddress: '',
    fromBtcAddress: '',
    fromChain: '',
    toChain: '',
    fromToken: '',
    tokenFromBackend: {},
    error: null,
    txClaimModalShown: false,
    setFromChain: fromChain => set({fromChain}),
    setToChain: toChain => set({toChain}),
    setFromToken: fromToken => set({fromToken}),
    setFromBtcAddress: fromBtcAddress => set({fromBtcAddress}),
    setToBtcAddress: toBtcAddress => set({toBtcAddress}),
    setTokenFromBackend: tokenFromBackend => set({tokenFromBackend}),
    setError: error => set({error}),
    setTxClaimModalShown: txClaimModalShown => set({txClaimModalShown}),
  }))

export const usePortalState = () => {
  if (!Store) Store = createStore()
  const useStore = Store
  const state = useStore()

  return state
}
