function BgLeftCircle(props) {
  return (
    <svg width="907" height="907" viewBox="0 0 907 907" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path opacity="0.3" d="M50.8717 503.79C23.0843 281.438 180.81 78.6593 403.163 50.8718C625.515 23.0844 828.294 180.811 856.081 403.163C883.868 625.515 726.142 828.294 503.79 856.081C281.438 883.869 78.6591 726.142 50.8717 503.79Z" stroke="#FFAF90"/>
<path opacity="0.8" d="M138.528 492.835C116.791 318.896 240.175 160.268 414.115 138.53C588.055 116.793 746.683 240.178 768.421 414.118C790.158 588.058 666.773 746.685 492.833 768.423C318.893 790.16 160.265 666.775 138.528 492.835Z" stroke="#FFAF90"/>
<path opacity="0.5" d="M104.981 497.029C80.9282 304.561 217.456 129.036 409.924 104.983C602.392 80.9303 777.916 217.458 801.969 409.926C826.022 602.394 689.494 777.918 497.026 801.971C304.558 826.024 129.034 689.496 104.981 497.029Z" stroke="#FFAF90"/>
<ellipse cx="453.474" cy="453.477" rx="294.977" ry="294.977" transform="rotate(-165 453.474 453.477)" fill="url(#paint0_linear_58_127)" fillOpacity="0.2"/>
<ellipse cx="453.473" cy="453.476" rx="245.844" ry="245.844" transform="rotate(-165 453.473 453.476)" fill="url(#paint1_linear_58_127)" fillOpacity="0.1"/>
<ellipse cx="453.473" cy="453.476" rx="245.844" ry="245.844" transform="rotate(-165 453.473 453.476)" fill="url(#paint2_linear_58_127)" fillOpacity="0.1"/>
<defs>
<linearGradient id="paint0_linear_58_127" x1="309.612" y1="71.4567" x2="453.474" y2="712.186" gradientUnits="userSpaceOnUse">
<stop stopColor="#917CFF"/>
<stop offset="1" stopColor="#FE7981"/>
</linearGradient>
<linearGradient id="paint1_linear_58_127" x1="333.574" y1="135.089" x2="453.473" y2="669.093" gradientUnits="userSpaceOnUse">
<stop stopColor="#917CFF"/>
<stop offset="1" stopColor="#FE7981"/>
</linearGradient>
<linearGradient id="paint2_linear_58_127" x1="333.574" y1="135.089" x2="453.473" y2="669.093" gradientUnits="userSpaceOnUse">
<stop stopColor="#917CFF"/>
<stop offset="1" stopColor="#FE7981"/>
</linearGradient>
</defs>
</svg>

  )
}

export default BgLeftCircle;
