function SVGZGSearchIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6469 25.3333C20.53 25.3333 25.2992 20.5577 25.2992 14.6667C25.2992 8.77563 20.53 4 14.6469 4C8.76381 4 3.99463 8.77563 3.99463 14.6667C3.99463 20.5577 8.76381 25.3333 14.6469 25.3333Z"
        stroke="#A9ABB2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9621 28.0002L22.1699 22.2002"
        stroke="#A9ABB2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SVGZGSearchIcon
