import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as dayjs from 'dayjs';
import {NavLink} from 'react-router-dom';

import { TokenIcon } from "../../components";
import { KeyOfCfx } from "../../../constants";
import { Button } from "../../../components";

function PendingTxList({ list }) {
  const { t } = useTranslation();

  const getSourceTokenName = (tx) => {
    const token = tx.fromToken;
    if (token.sfChain) {
      return token.sfChain === KeyOfCfx ? token.symbol : token.reference_symbol;
    }
    return token.symbol
  }

  const getFormattedDate = (timestamp) => {
    const ts = dayjs(timestamp)
    const date = ts.format('MM/DD/YYYY')
    const time = ts.format('HH:mm:ss')
    return `${date} ${time}`
  }

  return (
    <div>
      <p className='text-[24px] font-semibold mb-[28px] mt-[76px]'>{t('zgPortal.form.pendingTitle')}</p>
      <div className="flex flex-col gap-[16px]">
        {list.map(item => (
          <div key={item.hash} className="px-[24px] py-[28px] border rounded-[12px] flex justify-between items-center">
            <div className="flex gap-[30px]">
              <TokenIcon token={item.fromToken} size={64} />
              <div className="flex flex-col justify-center">
                <p className="text-[24px] font-semibold">{getSourceTokenName(item)}</p>
                <p className="text-[16px] font-medium leading-[130%]">{item.fromChain}</p>
                <p className="text-[16px] font-normal text-[#7B7B7B]">{getFormattedDate(item.timestamp)}</p>
              </div>
            </div>
            <div>
              <p className="text-[20px] font-semibold">{item.amount} {getSourceTokenName(item)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <Button className="mt-[60px] w-full md:w-auto px-[60px] md:px-[86px]">
          <NavLink to="/history">
            {t('zgPortal.form.seeHistory')}
          </NavLink>
        </Button>
      </div>
    </div>
  );
}

PendingTxList.propTypes = {
  list: PropTypes.array,
};

export default PendingTxList;
