import { InjectedConnector } from 'wagmi/connectors/injected'
import { KeyOfOkx } from '../constants';

export const okxConnector = new InjectedConnector({
  options: {
    name: KeyOfOkx,
    getProvider: () => {
      return window?.okxwallet
    }
  }
})
