import { useLocation } from "react-use";

import { FooterLogo, DiscordLogo, XLogo, TelegramLogo } from "../../../assets/svg";

const Footer = () => {
  const { pathname } = useLocation();

  if (pathname === '/notfound') {
    return null
  }

  const handleClickDiscord = () => {
    window.open("http://discord.gg/0glabs", "_blank");
  }
  const handleClickTelegram = () => {
    window.open("https://t.me/web3_0glabs", "_blank");
  }
  const handleClickX = () => {
    window.open("https://twitter.com/0G_labs", "_blank");
  }

  return (
    <div className="bg-secondary-3 w-full h-auto md:h-[224px] overflow-hidden flex flex-col items-center justify-between py-[40px] px-[64px] box-border text-base text-white z-10 flex-shrink-0">
      <div className="w-[335px] md:w-[1098px] h-auto md:h-[81px] justify-between items-start md:items-center flex flex-col md:flex-row">
        <FooterLogo />
        <div className="flex gap-[20px] md:gap-[80px] single-200-medium flex-row mt-[20px] md:mt-0">
          <a href="https://portal.0g.ai/" target="_blank" rel="noreferrer" className="no-underline text-neutral-100 cursor-pointer">Portal</a>
          <a href="https://www.0g.ai/contact" target="_blank" rel="noreferrer" className="no-underline text-neutral-100 cursor-pointer">Contact</a>
          <a href="https://blog.0g.ai/" target="_blank" rel="noreferrer" className="no-underline text-neutral-100 cursor-pointer">Blog</a>
          <a href="https://www.0g.ai/mediakit" target="_blank" rel="noreferrer" className="no-underline text-neutral-100 cursor-pointer">Media Kit</a>
          <a href="https://www.0g.ai/faq" target="_blank" rel="noreferrer" className="no-underline text-neutral-100 cursor-pointer">FAQ</a>
        </div>
      </div>
      <div className="flex justify-between w-[335px] md:w-[1100px] h-auto md:h-[40px] flex-col-reverse md:flex-row mt-[20px] md:mt-0">
        <p className="single-100-regular text-center mt-[40px] md:mt-0">© 2023 Zero Gravity. All rights reserved.</p>
        <div className="flex gap-[40px]">
          <button className="flex gap-[12px] py-[8px] cursor-pointer" onClick={handleClickDiscord}>
            <DiscordLogo />
          </button>
          <button className="flex gap-[12px] py-[8px] cursor-pointer" onClick={handleClickTelegram}>
            <TelegramLogo />
          </button>
          <button className="flex gap-[12px] py-[8px] cursor-pointer" onClick={handleClickX}>
            <XLogo />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
