import connectors from '../../../connectors'
import {useWalletState} from '../../../state/wallet'
import {
  KeyOfCoinbase,
  KeyOfMetaMask,
  KeyOfOkx,
  KeyOfPortal,
} from '../../../constants'
import {useEffect} from 'react'
import {useZGFormState} from '../../../state/zgForm'
import {Web3Provider} from '@ethersproject/providers'
// eslint-disable-next-line react/prop-types
export default function Web3Manager({children}) {
  const {init, initialize, setField} = useWalletState()
  const {setFromWallet, setWalletInit} = useZGFormState()

  useEffect(() => {
    if (!init) {
      const timer = setTimeout(() => {
        // init Fluent
        const conF = connectors.createConnector(KeyOfPortal)
        setField(KeyOfPortal, 'activate', conF.connect)

        // init metamask
        const optionM = {
          onAccountsChanged: accounts => {
            setField(KeyOfMetaMask, 'address', accounts[0])
          },
          onChainChanged: chainId => {
            setField(KeyOfMetaMask, 'chainId', '' + parseInt(chainId))
            conM
              .getProvider()
              .then(p =>
                setField(KeyOfMetaMask, 'web3Provider', new Web3Provider(p)),
              )
          },
        }
        const conM = connectors.createConnector(KeyOfMetaMask, optionM)
        setField(KeyOfMetaMask, 'activate', async id => {
          const {account, chain} = await conM.connect(id)
          setField(KeyOfMetaMask, 'address', account)
          setField(KeyOfMetaMask, 'chainId', '' + parseInt(chain.id))
        })
        conM
          .getProvider()
          .then(p =>
            setField(KeyOfMetaMask, 'web3Provider', new Web3Provider(p)),
          )

        // init coinbase wallet
        const optionC = {
          onAccountsChanged: accounts => {
            setField(KeyOfCoinbase, 'address', accounts[0])
          },
          onChainChanged: chainId => {
            setField(KeyOfCoinbase, 'chainId', '' + parseInt(chainId))
            conC
              .getProvider()
              .then(p =>
                setField(KeyOfCoinbase, 'web3Provider', new Web3Provider(p)),
              )
          },
        }
        const conC = connectors.createConnector(KeyOfCoinbase, optionC)
        setField(KeyOfCoinbase, 'activate', async id => {
          const {account, chain} = await conC.connect(id)
          setField(KeyOfCoinbase, 'address', account)
          setField(KeyOfCoinbase, 'chainId', '' + parseInt(chain.id))
        })
        conC
          .getProvider()
          .then(p =>
            setField(KeyOfCoinbase, 'web3Provider', new Web3Provider(p)),
          )

        // init okx wallet
        const optionO = {
          onAccountsChanged: accounts => {
            setField(KeyOfOkx, 'address', accounts[0])
          },
          onChainChanged: chainId => {
            setField(KeyOfOkx, 'chainId', '' + parseInt(chainId))
            conO
              .getProvider()
              .then(p =>
                setField(KeyOfOkx, 'web3Provider', new Web3Provider(p)),
              )
          },
        }
        const conO = connectors.createConnector(KeyOfOkx, optionO)
        setField(KeyOfOkx, 'activate', async id => {
          const {account, chain} = await conO.connect(id)
          setField(KeyOfOkx, 'address', account)
          setField(KeyOfOkx, 'chainId', '' + parseInt(chain.id))
        })
        conO
          .getProvider()
          .then(p => setField(KeyOfOkx, 'web3Provider', new Web3Provider(p)))

        initialize()

        // eager connect
        function eagerConnectM() {
          return conM.getProvider().then(async p => {
            if (!p) return false
            const accounts = await p.request({method: 'eth_accounts'})
            if (!accounts.length) return false
            const chainId = await p.request({method: 'eth_chainId'})
            setField(KeyOfMetaMask, 'address', accounts[0])
            setField(KeyOfMetaMask, 'chainId', '' + parseInt(chainId))
            setFromWallet(KeyOfMetaMask)
            return true
          })
        }

        function eagerConnectC() {
          return conC.getProvider().then(async p => {
            if (!p) return false
            const accounts = await p.request({method: 'eth_accounts'})
            if (!accounts.length) return false
            const chainId = await p.request({method: 'eth_chainId'})
            setField(KeyOfCoinbase, 'address', accounts[0])
            setField(KeyOfCoinbase, 'chainId', '' + parseInt(chainId))
            setFromWallet(KeyOfCoinbase)
            return true
          })
        }

        function eagerConnectO() {
          return conO.getProvider().then(async p => {
            if (!p) return false
            const accounts = await p.request({method: 'eth_accounts'})
            if (!accounts.length) return false
            const chainId = await p.request({method: 'eth_chainId'})
            setField(KeyOfOkx, 'address', accounts[0])
            setField(KeyOfOkx, 'chainId', '' + parseInt(chainId))
            setFromWallet(KeyOfOkx)
            return true
          })
        }

        eagerConnectM()
          .then(connected => (connected ? true : eagerConnectC()))
          .then(connected => (connected ? true : eagerConnectO()))

          setWalletInit()
      }, 1000)

      return () => clearTimeout(timer)
    }
  })

  return children
}
