import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './assets/locales/en.json'

const resources = {
  en: {translation: en},
}

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init({
  resources,
  debug: process.env.NODE_ENV !== 'production',
  fallbackLng: 'en',
  interpolation: {escapeValue: false},
})

export default i18next
