function SvgSuccess(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.7198 13.6836L17.4378 25.2625L12.3096 19.9994"
        stroke="#34C759"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="20" cy="20" r="19" stroke="#34C759" strokeWidth="2" />
    </svg>
  )
}

export default SvgSuccess
