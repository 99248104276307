import PropTypes from 'prop-types'
import {useCallback, useRef, useState} from 'react'
import {ZGBack, ZGSuccess} from '../../assets/svg'
import {Button, Loading, Notification} from '../../components'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {FileUploader} from 'react-drag-drop-files'
import Select from 'react-select'
import {uploadToS3} from '../../utils/aws'
import {calculateMD5, getFileExtension} from '../../utils'

const FormInput = ({label, ...props}) => {
  return (
    <div className="flex flex-col gap-[8px] flex-1">
      <label className="text-[16px] font-semibold leading-[18px] m-0 text-neutral-100">
        {label}
      </label>
      <input
        className="font-heading-h2 px-[16px] py-[23px] md:px-[24px] md:py-[27px] rounded-[20px] text-[16px] leading-[18px] m-0 placeholder-[#D1D5DB] border border-transparent focus:outline-none focus:border-[#DC3CB9] focus:border-solid focus:border"
        {...props}
      />
    </div>
  )
}

FormInput.propTypes = {
  label: PropTypes.string,
}

const options = [
  {value: 'Bugs/Feature Request', label: 'Bugs/Feature Request'},
  {value: 'Token Transaction', label: 'Token Transaction'},
  {value: 'Integration Inquiry', label: 'Integration Inquiry'},
  {value: 'Other', label: 'Other'},
]

function Request() {
  const [email, setEmail] = useState('')
  const [issue, setIssue] = useState('')
  const [subject, setSubject] = useState('')
  const [details, setDetails] = useState('')
  const [files, setFiles] = useState(null)
  const popupRef = useRef(null)

  const handleChange = file => {
    setFiles(file)
  }

  const names = files
    ? Array.from(files)
        .map(item => item.name)
        .join(',')
    : ''

  const history = useHistory()

  const [errMessage, setErrMessage] = useState('')
  const [submitting, setSubmitting] = useState('')

  const uploadAttachments = useCallback(async () => {
    const urls = []
    for (let file of files) {
      const extension = getFileExtension(file.name)
      const md5 = await calculateMD5(file)
      const result = await uploadToS3(`${md5}.${extension}`, file)
      urls.push(result)
    }
    return urls
  }, [files])

  const handleSubmit = useCallback(async () => {
    if (!email || !issue || !subject || !details) {
      setErrMessage('Please fill all the required fields')
      return
    }
    setErrMessage('')
    if (submitting) return
    setSubmitting(true)
    try {
      const urls = files ? await uploadAttachments(files) : []
      await fetch('/api/form', {
        method: 'POST',
        body: JSON.stringify({
          table: 'Feedbacks',
          email,
          issue: issue.value,
          subject,
          details,
          attachments: urls.map(item => ({
            filename: item.Key,
            url: item.Location,
          })),
        }),
      })
      popupRef.current?.classList.remove('hidden');
    } catch (err) {
      Notification.open({
        type: 'error',
        content: 'Something went wrong, please try again later.',
      })
    } finally {
      setSubmitting(false)
      setEmail('')
      setIssue('')
      setSubject('')
      setDetails('')
      setFiles(null)
    }
  }, [details, email, files, issue, subject, submitting, uploadAttachments])

  return (
    <div>
      <p className="text-center text-[40px] md:text-[48px] lg:text-[72px] xl:text-[128px] leading-[120%] m-0 mb-[40px] md:mb-[90px]">
        Submit a Request
      </p>
      <button
        className="flex gap-[4px] items-center mb-[21px] cursor-pointer"
        onClick={history.goBack}
      >
        <ZGBack />
        <p className="text-base font-semibold leading-[150%] text-G-700">
          Back
        </p>
      </button>
      <div className="py-[40px] px-[24px] md:py-[64px] md:px-[64px] xl:px-[72px] bg-contact-bg rounded-[20px] flex flex-col gap-[24px] lg:gap-[32px]">
        <div className="flex flex-col gap-[8px] flex-1">
          <label
            className="text-[16px] font-semibold leading-[18px] m-0 text-neutral-100"
            htmlFor="textarea"
          >
            Please select an issue below*
          </label>
          <Select
            theme={theme => ({
              ...theme,
              borderRadius: 20,
              colors: {
                ...theme.colors,
                primary25: '#1D1B2014',
                primary50: '#1D1B2014',
                primary: '#DC3CB9',
              },
            })}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                border: '1px',
                borderColor: 'transparent',
              }),
              placeholder: baseStyles => ({
                ...baseStyles,
                color: '#D1D5DB',
              }),
              valueContainer: baseStyles => ({
                ...baseStyles,
                padding: '27px 24px',
                '@media only screen and (max-width: 768px)': {
                  padding: '23px 16px',
                },
              }),
              input: baseStyles => ({
                ...baseStyles,
                padding: '0',
                margin: '0',
              }),
              menu: baseStyles => ({
                ...baseStyles,
                overflow: 'hidden',
                padding: '8px 0',
              }),
              indicatorSeparator: baseStyles => ({
                ...baseStyles,
                display: 'none',
              }),
            }}
            placeholder="Select option"
            value={issue}
            onChange={setIssue}
            options={options}
          />
        </div>
        <FormInput
          label="Email Address*"
          placeholder="example@email.com"
          value={email}
          onInput={e => setEmail(e.target.value)}
        />
        <FormInput
          label="Subject*"
          placeholder="Enter subject here"
          value={subject}
          onInput={e => setSubject(e.target.value)}
        />
        <div className="flex flex-col gap-[8px] flex-1">
          <label
            className="text-[16px] font-semibold leading-[18px] m-0 text-neutral-100"
            htmlFor="textarea"
          >
            Details*
          </label>
          <textarea
            className="font-heading-h2 p-6 rounded-[20px] text-[16px] leading-[18px] m-0 placeholder-[#D1D5DB] border border-transparent focus:outline-none focus:border-[#DC3CB9] focus:border-solid focus:border resize-none h-[142px]"
            placeholder="Please type your message here..."
            value={details}
            onInput={e => setDetails(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[8px] flex-1">
          <label
            className="text-[16px] font-semibold leading-[18px] m-0 text-neutral-100"
            htmlFor="upload"
          >
            Attachments (optional)
          </label>
          <FileUploader
            classes="font-heading-h2 p-6 bg-white rounded-[20px] text-[16px] leading-[18px] m-0 placeholder-[#D1D5DB] border border-transparent focus:outline-none focus:border-[#DC3CB9] focus:border-solid focus:border p-x-[24px] py-[40px] cursor-pointer"
            handleChange={handleChange}
            name="file"
            multiple={true}
            dropMessageStyle={{
              'border-color': 'black',
              color: 'transparent',
              'border-radius': '20px',
            }}
          >
            <div className="text-center text-ellipsis text-black">
              {!names && (
                <>
                  <span className="text-primary-fuscia">Add</span> or drop files here
                </>
              )}
              {names}
            </div>
          </FileUploader>
        </div>
        <div className="self-start">
          <Button
            className="text-base font-medium px-[24px]"
            onClick={handleSubmit}
            disabled={false}
            color='secondary'
          >
            {submitting && <Loading className="!w-5 !h-5" />}
            {!submitting && 'Submit'}
          </Button>
        </div>
        <div className={errMessage ? '' : 'hidden'}>
          <div className="text-[#EF1D1D] text-sm font-semibold">
            Please fill all the required fields
          </div>
        </div>
        <div ref={popupRef} className="hidden fixed z-50 top-0 left-0 w-full h-full bg-[rgba(89,53,135,0.30)] backdrop-blur-[7.5px] flex items-center justify-center">
          <div className="w-[636px] h-[289px] rounded-[20px] bg-white flex items-center justify-center flex-col">
            <ZGSuccess/>
            <p className="text-[24px] font-medium tracking-[0.48px] mt-[24px]">Thank you!</p>
            <p className="text-G-600 text-base font-medium leading-[130%] w-[510px] text-center mt-[8px]">Your submission has been received. We will be in touch and contact you shortly.</p>
            <Button onClick={history.goBack}>
              Back to Site
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

Request.propTypes = {
  backNav: PropTypes.string,
}

export default Request
