import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Modal } from "../../../components";
import BtcConfirmTips from './BtcConfirmTips';
import { useZGFormState, useIsFromChainBtc } from '../../../state/zgForm';
import { requestUserWallet } from '../../../utils/api';
import { ProxyUrlPrefix } from '../../../constants';

function BTCTransactionModal({ open, onClose, toAddress }) {
  const {
    fromToken,
    setFromBtcAddress,
  } = useZGFormState();

  const isFromChainBtc = useIsFromChainBtc();
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    if (isFromChainBtc && toAddress) {
      setShowDetail(false);
      requestUserWallet([
        ProxyUrlPrefix.shuttleflow,
        toAddress,
        undefined,
        'btc',
        'cfx',
        'in'
      ]).then(data => {
        setFromBtcAddress(data)
        setShowDetail(true);
      });
    }
  }, [fromToken, isFromChainBtc, setFromBtcAddress, toAddress, open])

  return (
    <Modal
      width="w-[992px]"
      open={open}
      onClose={onClose}
      content={(
        showDetail && <div>
          <BtcConfirmTips />
        </div>
      )}
    />
  );
}

BTCTransactionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  toAddress: PropTypes.string,
}

export default BTCTransactionModal;
