function FooterLogo(props) {
  return (
    <svg width="49" height="25" viewBox="0 0 49 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<mask id="mask0_88_2330" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="1" width="49" height="24">
<path d="M20.0902 20.9948C15.6708 25.4142 8.58898 25.5484 4.00795 21.3973L7.7299 17.6754C7.72989 17.6754 7.7299 17.6754 7.7299 17.6754L14.3135 11.0918L15.5513 12.3297L9.25869 18.6224C11.6096 19.6528 14.4521 19.2058 16.3766 17.2813C18.8833 14.7745 18.8833 10.7103 16.3766 8.20352C13.8698 5.69676 9.80557 5.69676 7.29881 8.20352C5.07389 10.4284 4.82374 13.8803 6.54837 16.3812L2.80068 20.1288C-0.955563 15.5445 -0.694069 8.769 3.58516 4.48978C8.1429 -0.067963 15.5325 -0.067963 20.0902 4.48978C24.6479 9.04752 24.6479 16.4371 20.0902 20.9948Z" fill="#FF3CD4"/>
<path d="M36.6881 24.3759C42.9381 24.3759 48.0406 19.4632 48.3446 13.2887H33.7705V15.0393H42.6695C41.7358 17.4303 39.4098 19.1241 36.6882 19.1241C33.1431 19.1241 30.2692 16.2502 30.2692 12.7051C30.2692 9.16004 33.1431 6.28617 36.6882 6.28617C39.8347 6.28617 42.4525 8.55017 43.0013 11.538H48.3013C47.7158 5.64037 42.7399 1.03424 36.6881 1.03424C30.2425 1.03424 25.0173 6.25945 25.0173 12.7051C25.0173 19.1507 30.2425 24.3759 36.6881 24.3759Z" fill="#FF3CD4"/>
</mask>
<g mask="url(#mask0_88_2330)">
<path d="M-0.571429 0.5H50.2857V24.943H-0.571429V0.5Z" fill="#FDFDFD"/>
</g>
</svg>


  )
}

export default FooterLogo;
