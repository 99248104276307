import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useZGFormState } from '../../../state/zgForm';
import { Modal, Tooltip } from "../../../components";
import { WalletIcon } from '../../components'
import {  WalletConfig } from '../../../constants'

import { useWalletState } from '../../../state/wallet';
import { SvgCheckedCircle, Info } from '../../../assets/svg';

const FromWalletModal = ({ open, onClose, hideList }) => {
  const { t } = useTranslation();
  const { setFromWallet, setFromToken, fromWallet } = useZGFormState();

  const {walletMap} = useWalletState()

  const handleSelectWallet = async (wallet) => {
    try {
      await walletMap[wallet.key].activate()
    } catch (e) {
      return window.open(WalletConfig[wallet.key].website, '_blank');
    }
    setFromWallet(wallet.key)
    setFromToken({})

    onClose();
  }

  const wallets = Object.values(WalletConfig).filter(wallet => !(hideList || []).includes(wallet.key));

  return (
    <Modal
      width='w-[736px] h-[90%]'
      open={open}
      onClose={onClose}
      closeIconClassName="w-[32px] h-[32px]"
      content={(
        <div className='md:py-[96px] py-[80px] h-full overflow-scroll'>
          <p className='text-[24px] font-semibold mb-[38px]'>{t('zgPortal.walletModal.sourceTitle')}</p>
            <div className=''>
              {wallets.map(wallet => (
                <button
                  key={wallet.key}
                  className={`h-[72px] w-full mb-[8px] flex items-center rounded-[12px] border border-[#EAECEF] md:px-8 px-4 md:py-6 py-4 space-between hover:bg-G-100 ${wallet.key === fromWallet ? 'border-S-600' : ''}`}
                  onClick={() => handleSelectWallet(wallet)}
                >
                  <div className='flex items-center gap-[16px]'>
                    <WalletIcon type={wallet.key} size={30}/>
                    <p className='text-[20px] font-medium'>{wallet.name}</p>
                    {wallet.info && <Tooltip content={wallet.info}><Info /></Tooltip>}
                  </div>
                  <div className='flex flex-1 justify-end'>
                    {wallet.key === fromWallet && <SvgCheckedCircle className='w-[20px] h-[20px]' />}
                  </div>
                </button>
              ))}
          </div>
        </div>
      )}
    />
  );
}

FromWalletModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hideList: PropTypes.array,
};

export default FromWalletModal;
