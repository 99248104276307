import { InjectedConnector } from 'wagmi/connectors/injected'
import { KeyOfMetaMask } from '../constants'


export const metaMaskConnector = new InjectedConnector({
  options: {
    name: KeyOfMetaMask,
    getProvider: () => {
      if (window?.ethereum) {
        const injectedProvider = window.ethereum

        if (injectedProvider.providers) {
          const realProvider = injectedProvider.providers.find(p => p.isMetaMask && !p.isFluent)
          if (realProvider) return realProvider
        }

        if (injectedProvider.isMetaMask && !injectedProvider.isFluent) {
          return injectedProvider
        }
      }
      return undefined
    }
  }
})
