import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Modal} from '../../components'
import {shortenAddress} from '../../utils/address'
import {getEllipsStr} from '../../utils'
import ScanItem from '../components/ScanItem'
import { ChainConfig } from '../../constants'

const TokenModal = ({open, onClose, transaction}) => {
  const {t} = useTranslation()
  const {
    status,
    nonce,
    timestamp,
    fromAddress,
    toAddress,
    amount,
    fromChain,
    toChain,
    fromToken,
    hash,
    toHash,
  } = transaction

  const shortendFromAddr = shortenAddress(fromChain,fromAddress)
  const fromMessage = shortendFromAddr ? `from: ${shortendFromAddr} ` : ''
  const fromChainConfig = ChainConfig[fromChain]
  const fromChainScanUrl = fromChainConfig?.scanTxUrl
  const toChainConfig = ChainConfig[toChain]
  const toChainScanUrl = toChainConfig?.scanTxUrl

  const displayData = {
    status,
    nonce,
    timestamp: new Date(timestamp).toLocaleString('en-US', {
      // to do: i18n
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }),
    hash: <ScanItem display={getEllipsStr(hash, 6, 4)} text={hash} prefix={fromChainScanUrl} />,
    toHash: <ScanItem display={getEllipsStr(toHash, 6, 4)} text={toHash} prefix={toChainScanUrl} />,
    amount: `${fromMessage}to: ${shortenAddress(toChain, toAddress)} amount: ${amount} ${
      fromToken?.token_abbr
    }`,
  }

  const renderList = () => {
    const elements = []

    for (const key in displayData) {
      elements.push(
        <div key={key} className="flex items-center mb-6">
          <div className="font-medium w-5/12">{t(`history.${key}`)}</div>
          <div className="font-medium w-7/12">{displayData[key]}</div>
        </div>,
      )
    }

    return elements
  }

  return (
    <Modal
      width="w-[375px] md:w-[774px] lg:w-[992px] md:p-16 lg:p-28"
      open={open}
      onClose={onClose}
      content={
        <div>
          <h1 className="text-xl font-semibold mb-18">{t('history.detail')}</h1>
          {renderList()}
        </div>
      }
    />
  )
}

TokenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
}

export default TokenModal
