import {useState} from 'react'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ZGTokenSelectionChevron from '../../../assets/svg/ZGTokenSelectionChevron'
import {Input} from '../../../components'

import Account from './Account'
import TokenModal from './TokenModal'
import { TokenIcon } from '../../components'

const GroupBox = ({tokenInfo, setToken, address, setAmount, amount, tokenList, disableInput, handleOpenWalletModal, wallet, hideAccount, setMax, tokenModalTitle, balance}) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const handleChangeAmount = e => {
    setAmount && setAmount(e.target.value)
  }
  const handleShowModal = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div className="flex border border-[#EAECEF] py-6 px-[24px] justify-between items-center xl:flex-row flex-col rounded-xl bg-white">
      <div className='flex justify-between items-center w-full gap-[8px] mr-[16px]'>
        <Input
          width='w-full'
          className="md:text-[40px] text-[24px] px-0"
          value={amount}
          onChange={handleChangeAmount}
          bordered={false}
          placeholder="0"
          disabled={disableInput}
          containerClassName='py-0 md:py-0 px-[12px] md:px-[6px]'
        />
        {disableInput ? (
          <span className='w-[50.41px] h-[32px] inline-block' />
        ) : (
          <button onClick={setMax} className='border border-[#A9ABB2] rounded-[12px] px-[8px] md:py-[11px] py-[8px] bg-[#F5F5F5] font-medium md:text-[14px] text-[12px]'>{t('zgPortal.form.maxButton')}</button>
        )}
      </div>
      <div className='flex gap-[16px] items-center justify-between xl:w-auto w-full xl:mt-0 md:mt-[32px] mt-[12px] md:flex-row flex-col'>
        <button
          className="block md:w-auto w-full"
          onClick={handleShowModal}
        >
          <div className='flex justify-between items-center gap-[16px]'>
            <div className='flex gap-[16px] items-center'>
              <TokenIcon token={tokenInfo} size={36}/>
              {tokenInfo.name ? (
                <div className='flex flex-col items-start justify-center'>
                  <p className='color-[#A9ABB2] text-[20px] font-semibold leading-[130%]'>{tokenInfo.symbol}</p>
                  <p className="color-[#A9ABB2] text-[16px] truncate w-[92px] text-left">{tokenInfo.chainName}</p>
                </div>
              ) : (
                <>
                  <p className="text-[#A9ABB2] whitespace-nowrap md:text-[16px] text-[14px]">{t('selectToken')}</p>
                </>
              )}
            </div>
            <ZGTokenSelectionChevron />
          </div>
        </button>
        {hideAccount ? <div className='w-[215px]'/> : <Account account={address} handleOpenWalletModal={handleOpenWalletModal} wallet={wallet} balance={balance} />}
      </div>
      <TokenModal
        open={showModal}
        onClose={handleCloseModal}
        wallet={wallet}
        setToken={setToken}
        tokenList={tokenList}
        tokenModalTitle={tokenModalTitle}
      />
    </div>
  )
}

GroupBox.propTypes = {
  tokenInfo: propTypes.object.isRequired,
  setToken: propTypes.func.isRequired,
  address: propTypes.string,
  setAmount: propTypes.func,
  amount: propTypes.string,
  tokenList: propTypes.array.isRequired,
  disableInput: propTypes.bool,
  handleOpenWalletModal: propTypes.func,
  wallet: propTypes.string,
  hideAccount: propTypes.bool,
  setMax: propTypes.func,
  tokenModalTitle: propTypes.string,
  balance: propTypes.string,
}

export default GroupBox
