import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { useZGFormState } from '../../../state/zgForm';

import { Modal, Checkbox, Input, Button } from "../../../components";
import { WalletIcon } from '../../components'
import { Checked, NoChecked } from '../../../assets/svg';
import {  KeyOfCfx, KeyOfPortal, WalletConfig } from '../../../constants'
import SVGZGConnectWallet from '../../../assets/svg/ZGConnectWallet';
import { SvgCheckedCircle, SvgClear } from '../../../assets/svg';
import SvgArrowLeft from '../../../assets/svg/color/ArrowLeft';

import { useWalletState } from '../../../state/wallet';

const ToWalletModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [understand, setUnderstand] = useState(false);
  const {
    fromAddress,
    setToAddress,
    sameWallet,
    setSameWallet,
    setToWallet,
    toToken,
    toWallet,
  } = useZGFormState();
  const [showEnterAddress, setShowEnterAddress] = useState(false);
  const [address, setAddress] = useState('');

  const handleSwitchSameAsSource = () => {
    setSameWallet(!sameWallet);
    if (!sameWallet) {
      setToAddress(fromAddress);
      onClose();
    }
  }

  const handleInputAddress = (e) => {
    setAddress(e.target.value);
  }

  const {walletMap} = useWalletState()

  const handleSelectWallet = async (wallet) => {
    try {
      await walletMap[wallet.key].activate()
    } catch (e) {
      return window.open(WalletConfig[wallet.key].website, '_blank');
    }
    setToWallet(wallet.key)
    setToAddress('') // when select wallet, clear the manually input address

    onClose();
  }

  const wallets = (toToken?.sfChain === KeyOfCfx) ? [WalletConfig[KeyOfPortal]] : Object.values(WalletConfig)
  const disableToSelect = sameWallet
  const disabledClass = disableToSelect ? 'bg-[#EEF0F2] cursor-default opacity-40' : ''

  const handleClickEnterAddress = () => {
    if (disableToSelect) return;
    setShowEnterAddress(true);
  }

  const handleClickAddressContinue = () => {
    setToAddress(address);
    onClose && onClose();
  }

  return (
    <Modal
      width='w-[736px] h-[90%]'
      open={open}
      onClose={onClose}
      closeIconClassName="w-[32px] h-[32px]"
      content={(
        <div className='md:py-[96px] py-[80px] h-full overflow-scroll'>
          {!showEnterAddress && (
            <>
              <p className='text-[24px] font-semibold mb-[38px]'>{t('zgPortal.walletModal.destinationTitle')}</p>
              {(toToken.sfChain !== KeyOfCfx) && (
                <button className='flex items-center rounded-[12px] gap-[16px] md:gap-[24px] mb-[32px] mt-[40px]' onClick={handleSwitchSameAsSource}>
                  <Checkbox customShow checked={sameWallet} onChange={handleSwitchSameAsSource}>
                    {sameWallet && <Checked className='w-[24px] h-[24px]' />}
                    {!sameWallet && <NoChecked className='w-[24px] h-[24px] text-[#843EB4]' />}
                  </Checkbox>
                  <p className='text-[20px] font-medium'>{t('zgPortal.walletModal.same')}</p>
                </button>
              )}
              <div className=''>
                {wallets.map(wallet => (
                  <button
                    key={wallet.key}
                    className={`h-[72px] w-full mb-[8px] flex items-center rounded-[12px] border border-[#EAECEF] md:px-8 px-4 md:py-6 py-4  space-between hover:bg-G-100 ${wallet.key === toWallet ? 'border-S-600' : ''} ${disabledClass}`}
                    onClick={() => !disableToSelect && handleSelectWallet(wallet)}
                  >
                    <div className='flex items-center gap-[16px]'>
                      <WalletIcon type={wallet.key} size={30}/>
                      <p className='text-[16px] md:text-[20px] font-medium'>{wallet.name}</p>
                    </div>
                    <div className='flex flex-1 justify-end'>
                      {wallet.key === toWallet && <SvgCheckedCircle className='w-[20px] h-[20px]' />}
                    </div>
                  </button>
                ))}
              </div>
              <div>
                <button
                  className={`h-[72px] w-full mb-[8px] flex items-center rounded-[12px] border border-[#EAECEF] md:px-8 px-4 md:py-6 py-4 gap-[16px] hover:bg-G-100 ${disabledClass}`}
                  onClick={handleClickEnterAddress}
                >
                  <SVGZGConnectWallet className='w-[30px] h-[30px] color-black' />
                  <p className='text-[16px] md:text-[20px] font-medium'>{t('zgPortal.walletModal.enterWalletAddress')}</p>
                </button>
              </div>
            </>
          )}
          {showEnterAddress && (
            <>
              <SvgArrowLeft className='absolute top-[24px] left-[24px] text-[#A9ABB2] cursor-pointer' onClick={() => setShowEnterAddress(false)} />
              <p className='text-[24px] font-semibold mb-[24px] md:mb-[38px] leading-[105%] md:leading-[100%]'>{t('zgPortal.walletModal.enterWalletAddress')}</p>
              <Input
                width='h-[72px]'
                containerClassName='px-[16px] md:px-[24px] py-[24px] rounded-[12px]'
                className='rounded-[12px]'
                size='large'
                placeholder='Enter destination address'
                onChange={handleInputAddress}
                value={address}
                suffix={address ? <SvgClear onClick={() => setAddress('')} /> : null}
              />
              <button className='flex mt-[12px] gap-[8px]' onClick={() => setUnderstand(!understand)}>
                <Checkbox customShow checked={understand} onChange={() => setUnderstand(!understand)}>
                  {understand && <Checked className='w-[24px] h-[24px]' />}
                  {!understand && <NoChecked className='w-[24px] h-[24px]' />}
                </Checkbox>
                <p className='w-full text-left text-[16px] font-medium'>{t('zgPortal.walletModal.clarification')}</p>
              </button>
              <div className='absolute bottom-0 flex flex-col-reverse md:flex-row gap-[8px] md:gap-[24px] mb-[63px] w-auto md:w-full justify-center'>
                <Button className='w-[306px] md:w-auto md:px-[96px]' onClick={() => setShowEnterAddress(false)} variant='outlined'>{t('zgPortal.walletModal.backButton')}</Button>
                <Button className='w-[306px] md:w-auto md:px-[96px]' onClick={handleClickAddressContinue} disabled={!understand || !address}>{t('zgPortal.walletModal.continueButton')}</Button>
              </div>
            </>
          )}
        </div>
      )}
    />
  );
}

ToWalletModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ToWalletModal;
