import { ChainConfig, KeyOfPortal } from '../constants'
import {
  useConnect as useConnectPortal,
} from './usePortal'
import { useWalletState } from '../state/wallet'
import connecors from '../connectors'
import { useMemo } from 'react'

export function useWallet(wallet) {
  const connectObjPortal = useConnectPortal()

  const {walletMap} = useWalletState()

  let data = {}
  switch (wallet) {
    case KeyOfPortal:
      data = connectObjPortal
      break
    default:
      data = {
        ...(walletMap[wallet] || {}),
        connector: connecors.getConnector(wallet),
      }
      break
  }
  return data
}

export function useWeb3Wallets() {
  const {walletMap} = useWalletState()

  const res = Object.values(walletMap).filter(wallet => wallet.address && !wallet.isCore).map(wallet => wallet.address)
  return res
}

/**
 * whether this address is native token in this chain
 * @param {*} chain
 * @param {*} address
 * @returns
 */
export function useIsNativeToken(chain, tokenAddress) {
  return useMemo(
    () => ChainConfig[chain]?.tokenName?.toLowerCase() === tokenAddress,
    [chain, tokenAddress],
  )
}
