import PropTypes from 'prop-types'
import * as dayjs from 'dayjs'
import {ChainConfig} from '../../constants/chainConfig'
import {ZGArrowRight} from '../../assets/svg'
import {TokenIcon} from '../components'

function TokenInfo({token, chain, amount, className}) {
  const {shortName} = chain ? ChainConfig[chain] : {}

  return (
    <div className={`flex items-center flex-1 ${className}`}>
      <TokenIcon token={token} chain={chain} size={48} />
      <div className="ml-3.5 hidden md:inline">
        <div className="text-2lg font-semibold">
          {amount} {token.symbol}
        </div>
        <div className="font-medium">{shortName}</div>
      </div>
    </div>
  )
}

TokenInfo.propTypes = {
  token: PropTypes.object,
  chain: PropTypes.string,
  amount: PropTypes.string,
  className: PropTypes.string,
}

function HistoryItem({historyItemData, onClick}) {
  const {
    fromToken,
    fromChain,
    toToken,
    toChain,
    amount,
    amountReceived,
    timestamp,
  } = historyItemData

  const ts = dayjs(timestamp)
  const date = ts.format('MM/DD/YYYY')
  const time = ts.format('HH:mm:ss')

  return (
    <div
      className="flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center p-6 border rounded-xl mt-2"
      onClick={onClick}
      aria-hidden="true"
    >
      <div className="lg:w-3/12 mt-4 lg:mt-0">
        <span>{date}</span>
        <br className="hidden lg:inline"/>
        <span className="inline lg:hidden">&nbsp;</span>
        {time}
      </div>
      <div className="flex items-center w-[100%] lg:w-8/12 justify-between">
        <TokenInfo token={fromToken} chain={fromChain} amount={amount} />
        <ZGArrowRight />
        <TokenInfo token={toToken} chain={toChain} amount={amountReceived} className='justify-end' />
      </div>
    </div>
  )
}

HistoryItem.propTypes = {
  historyItemData: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default HistoryItem
