import {
  WalletCoinbase,
  WalletFluent,
  WalletMetamask,
  WalletOkx,
} from '../assets/svg'

export const KeyOfMetaMask = 'MetaMask'
export const KeyOfCoinbase = 'Coinbase'
export const KeyOfOkx = 'Okx'
export const KeyOfPortal = 'Fluent'

export const WalletConfig = {
  [KeyOfMetaMask]: {
    key: KeyOfMetaMask,
    name: 'MetaMask',
    website: 'https://metamask.io',
    icon: WalletMetamask,
  },
  [KeyOfCoinbase]: {
    key: KeyOfCoinbase,
    name: 'Coinbase Wallet',
    website: 'https://www.coinbase.com/wallet',
    icon: WalletCoinbase,
  },
  [KeyOfOkx]: {
    key: KeyOfOkx,
    name: 'Okx Wallet',
    website: 'https://www.okx.com/web3',
    icon: WalletOkx,
  },
  [KeyOfPortal]: {
    key: KeyOfPortal,
    name: 'Fluent',
    website: 'https://fluentwallet.com',
    icon: WalletFluent,
    info: 'Fluent Wallet only supports tokens on Conflux Core Space'
  },
}

export const SupportedWallets = Object.keys(WalletConfig)
