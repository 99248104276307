import PropTypes from 'prop-types';

const Tab = ({ selected, label, ...props }) => {
  const containerClass = selected ? 'bg-S-100' : 'bg-transparent';
  const labelClass = selected ? 'text-primary-fuscia' : 'text-G-400';

  return (
    <button className={`p-4 rounded-[30px] items-center justify-center ${containerClass}`} {...props}>
      <p className={`text-14px font-medium ${labelClass}`}>{label}</p>
    </button>
  );
}

Tab.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
}

export default Tab;
