import { useState } from "react";
import PropTypes from "prop-types";

import Tab from "./Tab";

const Tabs = ({ tabs, onSelect, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    onSelect && onSelect(tab);
  };

  return (
    <div className="flex flex-row justify-center items-center gap-[8px]" {...props}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          selected={tab.label === selectedTab.label}
          onClick={() => handleSelectTab(tab)}
        />
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onSelect: PropTypes.func,
}

export default Tabs;
