import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Loading as LoadingSvg} from '../../../assets/svg'
import SVGZGConnectWallet from '../../../assets/svg/ZGConnectWallet';
import SVGZGEditWallet from '../../../assets/svg/ZGEditWallet';
import { useZGFormState } from '../../../state/zgForm';
import { WalletIcon } from '../../components';


const Account = ({ account, handleOpenWalletModal, wallet, hideEdit, balance }) => {
  const { walletInit } = useZGFormState()
  const { t } = useTranslation();
  const showBalance = useMemo(() => {
    return balance !== undefined;
  }, [balance]);

  const displayAccount = useMemo(() => {
      if (!account) return null;
      return `${account.slice(0, 6)}...${account.slice(-4)}`;
  }, [account]);

  const handleConnectWallet = () => {
    handleOpenWalletModal();
  }

  return (
      <>
          {
            (account) ? (
                <div className='flex flex-0 items-center justify-between md:w-[215px] w-full'>
                  {wallet ? <WalletIcon type={wallet} className='mr-[10px]' /> : <div className='w-[30px] mr-[10px]' />}
                  <div className='mr-[24px]'>
                    <p className='text-black text-[16px] font-medium'>{displayAccount}</p>
                    {showBalance && (
                      <p className='text-[#424242] text-[14px] font-medium'>
                        {t('balance')} {balance}
                      </p>
                    )}
                  </div>
                  {!hideEdit && <SVGZGEditWallet onClick={handleOpenWalletModal} className='cursor-pointer' />}
                </div>
            ) : (
                <button className='flex gap-2 justify-center items-center md:py-[8px] py-[4px] px-[48px] rounded-[24px] md:w-auto w-full bg-primary' onClick={handleConnectWallet}>
                  {walletInit && <>
                    <SVGZGConnectWallet style={{
                      color: 'white'
                    }} />
                    <p className='text-sm font-medium text-white md:text-[16px] text-[14px]'>{t('zgPortal.form.connect')}
                    </p>
                  </>}
                  {!walletInit && <LoadingSvg className={`animate-spin text-gray-20 w-6 h-6`} />}
                </button>
            )
          }
      </>
  )
}

Account.propTypes = {
  tokenInfo: PropTypes.object,
  account: PropTypes.string,
  handleOpenWalletModal: PropTypes.func,
  wallet: PropTypes.string,
  hideEdit: PropTypes.bool,
  balance: PropTypes.string,
}

export default Account;
