import PropTypes from 'prop-types'
import {ChainIconSrc, findChainKeyById} from '../../../constants/chainConfig'
import {TokenIconSrc} from '../../../constants/tokenConfig'

function TokenIcon({token, size = 38}) {
  const {token_abbr = '', chain_id = ''} = token

  const tokenIcon = TokenIconSrc(token_abbr)
  const chain = findChainKeyById(chain_id)
  const networkIcon = ChainIconSrc(chain)
  const networkSize = (size * 16) / 38

  return (
    <div
      className={"relative"}
      style={{
        width: size,
        height: size,
      }}
    >
      <img
        src={tokenIcon}
        alt={`token ${token_abbr}`}
        className="max-w-none"
        width={size}
        height={size}
      />
     {chain_id &&  <img
        src={networkIcon}
        alt={`network ${chain}`}
        width={networkSize}
        height={networkSize}
        className="max-w-none	absolute bottom-0 right-0 z-10"
      />}
    </div>
  )
}

TokenIcon.propTypes = {
  token: PropTypes.object,
  size: PropTypes.number,
}

export default TokenIcon
