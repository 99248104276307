import {default as AWS} from 'aws-sdk';

AWS.config.update({
  region: 'us-west-1',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  })
});

export function uploadToS3(key, file) {
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: 'zg-portal-feedback-attachments',
      Key: key,
      Body: file
    }
  })
  return upload.promise()
}
